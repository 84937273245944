import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as firebase from 'firebase';
import { DataCollectionsService } from '../data-collections.service';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  public language:string;
  public regSections: Array<any> = [];
  public errorMsg: Array<any> = [];
  public onRegisterForm: FormGroup;
  public email: any;
  public password: any;
  public confirmPassword: any;
  public uid: any;
  public loading = false;
  public userRegistered = false;
  public dobDays: Array<any> = [];
  public dobYears: Array<any> = [];
  public showAlert: any = false;
  public emailAlert: any = false;
  public emailError: any;

  public user = {
    nickName: "",
    homeTown: "",
    searchingQualities: "",
    description: "",
    interestedIn: "love",
    lookingFor: "men",
    birthDay: "day",
    birthMonth: "month",
    birthYear: "year",
    dob: "",
    myUnique: "helping",
    tos: "",
    privacyPolicy: "",
    email: "",
    password: "",
    sexe: "men",
  }
  town = new FormControl();
  townPattern = "[A-Za-z]*";
  townNext = true;
  
  constructor(
    public route: ActivatedRoute,
    public _fb: FormBuilder, 
    public service: DataCollectionsService, 
    public authService: AuthService,
    private _translate: TranslateService, 
    public router: Router
  ) {

    this.getDates();
    if (this.service.nickName) {
      this.regSections[1] = true;
    }
    else {
      this.regSections[0] = true;
    }

  }

  ngOnInit() {

    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else { this.language = this._translate.currentLang; }
   
    this.onRegisterForm = this._fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
      ])],
      password: ['', Validators.compose([
        Validators.required
      ])],
      confirmPassword: ["", Validators.compose([
        Validators.required
      ])],
    })

  }


  nextSection(attribute, value, index) {
    if (!value) {
      this.errorMsg[index] = true;
    }
    else {
      this.user[attribute] = value;
      this.regSections[index] = false;
      this.regSections[index + 1] = true;
      this.errorMsg[index] = false;
      console.log(this.user);
      this.service.user = this.user;
    }
  }


  backSection(attribute, value, index) {
    this.user[attribute] = value;
    this.regSections[index] = false;
    this.regSections[index - 1] = true;
    this.errorMsg[index] = false;
    console.log(this.user);
    this.service.user = this.user;
  }

  onChange(value, index) {
    this.townNext = false;
    this.errorMsg[index] = false;

    if (!value) {
      this.errorMsg[index] = true;
    }
    if (index == 5) {

      if (this.town.errors) {
        if (this.town.errors.pattern) {
          this.errorMsg[index] = true; this.townNext = true;
        }
      }
    }
  }

  checkDOB(index) {
    if (this.user.birthDay == "day" || this.user.birthMonth == "month" || this.user.birthYear == "year") {
      this.errorMsg[index] = true;
    }
    else {
      this.user.dob = this.user.birthYear + '/' + this.user.birthMonth + '/' + this.user.birthDay
      this.nextSection("dob", this.user.dob, 4);
    }
  }

  saveCheckbox(index) {
    debugger;
    if (!this.user.tos) {
      this.errorMsg[index] = true;
    } else {

      this.nextSection("tos", this.user.tos, 9);
    }
    /* if (!this.user.tos || !this.user.privacyPolicy) {
      this.errorMsg[index] = true;
    }
    else {
      this.nextSection("tos", this.user.tos, 8);
    } */
  }
 
  onLoginFacebook(): void {
    this.authService.loginFacebookUser()
      .then((res) => {
        this.registerUserFacebook();
        this.onLoginFacebookRedirect();
      }).catch(err => console.log('err', err.message));
  }
  onLoginFacebookRedirect(): void {
    this.router.navigate(['/home']);
    //window.location.replace('/welcome-msg');

  }
  onLoginGoogle(): void {
    this.authService.loginGoogleUser()
      .then((res) => {
        this.registerUserFacebook();
        this.onLoginFacebookRedirect();
      }).catch(err => console.log('err', err.message));
  }

  registerUserFacebook() {
    var self = this;
    self.loading = true;

    if (firebase.auth().currentUser) {
      self.uid = firebase.auth().currentUser.uid;
      self.service.nickName = localStorage.getItem('fullName');
      self.user.nickName = localStorage.getItem('fullName');
      self.user.email = localStorage.getItem('email');

      //  self.uid =localStorage.getItem('uid');
      /*localStorage.setItem('uid', self.uid);
      localStorage.setItem('fullName', self.user.nickName);
      localStorage.setItem('user_logged_in', 'true');
      localStorage.setItem('popupSeen', 'false');
      localStorage.setItem('email', self.user.email);*/
    }
    this.saveDatatoUserTableAfterRegisterFB();
  }
  registerUser() {
    var self = this;
    if (self.password != self.confirmPassword) {
      alert("Password Mismatch");
    }
    else {
      self.user.email = self.email;
      self.user.password = self.password;
      self.loading = true;

      firebase.auth().createUserWithEmailAndPassword(self.email, self.password).
        then((user) => {
          localStorage.setItem('user_logged_in', 'true');
          this.SendVerificationMail();
          if (firebase.auth().currentUser) {
            self.uid = firebase.auth().currentUser.uid;
            localStorage.setItem('uid', self.uid);
            localStorage.setItem('fullName', self.user.nickName);
            localStorage.setItem('user_logged_in', 'true');
            localStorage.setItem('popupSeen', 'false');
            localStorage.setItem('email', self.user.email);
          }

          this.saveDatatoUserTableAfterRegister();

        })
        .catch((error) => {
          var errorMessage = error.message;
          self.loading = false;
          this.emailError = errorMessage;
          this.emailAlert = true;
        });



    }


  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return firebase.auth().currentUser.sendEmailVerification()
      .then(() => {
        //this.loading = false;
        //this.saveDatatoUserTableAfterRegister();
        //this.userRegistered = true;


        // this.router.navigate(['<!-- enter your route name here -->']);
      })
  }
  saveDatatoUserTableAfterRegisterFB() {

    var self = this;
    var postData: any = {
      nickName: self.service.nickName || self.user.nickName,
      interestedIn: self.user.interestedIn,
      lookingFor: self.user.lookingFor,
      dob: self.user.dob,
      homeTown: self.user.homeTown,
      myUnique: self.user.myUnique,
      description: self.user.description,
      searchingQualities: self.user.searchingQualities,
      tos: self.user.tos,
      privacyPolicy: self.user.privacyPolicy,
      email: self.user.email,
      sexe: self.user.sexe,
      uid: self.uid,
      status: "single",
      children: 0,
      height: "",
      eyes: "",
      hair: "",
      silhouette: "",
      handyplus: self.user.myUnique,
      timestamp: Number(new Date()),
      profileUrl: localStorage.getItem('profileUrl'),
    };
    // Write the new post's data simultaneously in the posts list and the user's post list.
    var updates = {};
    updates['/users/' + self.uid] = postData;
    firebase.database().ref().update(updates).then(() => {

      self.loading = false;
      self.regSections[10] = false;
      self.userRegistered = true;
      self.showAlert = true;
      self.emailAlert = false;
    });
  }

  saveDatatoUserTableAfterRegister() {

    var self = this;
    var postData: any = {
      nickName: self.service.nickName || self.user.nickName,
      interestedIn: self.user.interestedIn,
      lookingFor: self.user.lookingFor,
      dob: self.user.dob,
      homeTown: self.user.homeTown,
      myUnique: self.user.myUnique,
      description: self.user.description,
      searchingQualities: self.user.searchingQualities,
      tos: self.user.tos,
      privacyPolicy: self.user.privacyPolicy,
      email: self.user.email,
      sexe: self.user.sexe,
      uid: self.uid,
      status: "single",
      children: 0,
      height: "",
      eyes: "",
      hair: "",
      silhouette: "",
      handyplus: self.user.myUnique,
      timestamp: Number(new Date()),
    };
    // Write the new post's data simultaneously in the posts list and the user's post list.
    var updates = {};
    updates['/users/' + self.uid] = postData;
    firebase.database().ref().update(updates).then(() => {

      self.loading = false;
      self.regSections[10] = false;
      self.userRegistered = true;
      self.showAlert = true;
      self.emailAlert = false;
    });
  }

  getDates() {
    for (var i = 1; i <= 31; i++) {
      this.dobDays.push(i);
    }
    var currentYear = (new Date()).getFullYear();
    for (var i = currentYear - 18; i > 1950; i--) {
      this.dobYears.push(i);
    }
  }


}
