import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
 
  public language: string;
  constructor(public _translate: TranslateService) {}

  ngOnInit() {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    }else
    {this.language = this._translate.currentLang;}
   
  }
   
}
