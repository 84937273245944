import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { DataCollectionsService } from '../data-collections.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  public language :string;
  public loading: any = false;
  public user: any = {};
  public uid: any;

  constructor(
    public service: DataCollectionsService, 
    public router: Router,
    private _translate: TranslateService) {

    this.uid = localStorage.getItem("uid");
    this.getUserData();
    if (!this.user.searchingFor) {
      this.user.searchingFor = {
        status: "notMatter",
        handyplus: "Doesn’t matter"
      };
    }
    

  }

  ngOnInit() {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    }else
    {this.language = this._translate.currentLang;}
  }

  getUserData() {
    this.user.lifeStyle = {};
    this.loading = true;
    debugger;
    firebase.database().ref().child('users/' + this.uid)
      .once('value', (snapshot) => {
        debugger;
        var user = snapshot.val();
        console.log("user is: ", user);
        this.user = user;   
        var dob = new Date(this.user.dob);
        var timeDiff = Math.abs(Date.now() - dob.getTime());
        this.user.userAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
        this.loading = false;
      });   
  }

  editProfile() {
    this.router.navigate(['/edit-profile']);
    this.service.user = this.user;
  }

  getUrl() {
    return "url(" + this.user.profileHeader + ") center no-repeat";
  }
 
}
