// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase : {
    apiKey: "AIzaSyDTkBw2kNhcgZ0CiNHfWlA43bVgfEE1XQE",
    authDomain: "myhandyplus-66863.firebaseapp.com",
    databaseURL: "https://myhandyplus-66863.firebaseio.com",
    projectId: "myhandyplus-66863",
    storageBucket: "myhandyplus-66863.appspot.com",
    messagingSenderId: "1099099676479",
    appId: "1:1099099676479:web:138f26cbbd0be309b2bd8a",
    measurementId: "G-DHJ6THFXRM"
  }
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
