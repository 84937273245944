import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public language: string;
  public emailAddress: any;
  public loading = false;
  public showError = false;

  constructor(private _translate: TranslateService) { }

  ngOnInit() {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else {
      this.language = this._translate.currentLang;
    }
  }

  resetPassword() {
    var self = this;
    if (!self.emailAddress) {
      self.showError = true;
    }
    else {
      self.showError = false;
      self.loading = true;
      var auth = firebase.auth();
      auth.sendPasswordResetEmail(self.emailAddress)
        .then(() => {
          self.loading = false;
          // alert("Email has been sent successfuly!");
        })
        .catch((error) => {
          self.loading = false;
          alert(error);
        });
    }
  }


}
