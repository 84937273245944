import { Component, OnInit} from '@angular/core';
import { DataCollectionsService } from '../data-collections.service';
import * as firebase from 'firebase';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public language: string;
  public userName: any;
  public userImg: any;
  public userSexe: any;
  public blockedUser: Array<any> = [];
  public allChats: Array<any> = [];
  public allChatsUser: Array<any> = [];
  public allUsers: Array<any> = [];
  public loading = false;
  badgeCount: number = 0;
  public user: any = {};
  public uid: any;

  constructor(
    public service: DataCollectionsService,
    public route: ActivatedRoute,
    public router: Router,
    public _translate: TranslateService) {

    this.badgeCount = 0;
    this.userName = localStorage.getItem('fullName');
    if (localStorage.getItem('profileUrl')) {
      this.userImg = localStorage.getItem('profileUrl');
    }
    if (localStorage.getItem('user_logged_in') == 'false') {
      this.router.navigate(['/login']);
    }
    this.userSexe = this.service.user.sexe;

  }


  ngOnInit() {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else { this.language = this._translate.currentLang; }
  }
  getUserData() {
    this.user.lifeStyle = {};
    this.loading = true;
    // debugger;
    firebase.database().ref().child('users/' + this.uid)
      .once('value', (snapshot) => {
        //debugger;
        var user = snapshot.val();
        this.user = user;
        var dob = new Date(this.user.dob);
        var timeDiff = Math.abs(Date.now() - dob.getTime());
        this.user.userAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
        localStorage.setItem('currentUser', JSON.stringify(this.user));
        localStorage.setItem('currentUser', JSON.stringify(this.user));

      });
    this.loading = false;

  }

  logOut() {
    var self = this;
    var user = firebase.auth().currentUser;
    if (user) {
      firebase.auth().signOut().then(() => {
        let lang = localStorage.getItem('lg');
        localStorage.clear();
        localStorage.setItem('lg', lang);
        localStorage.setItem('user_logged_in', 'false');

        self.router.navigate(['/login']);

        // window.location.reload();
      })
        .catch((error) => {
          alert(" Error Signing Out");
        })
    }
    else {
      localStorage.clear();
      localStorage.setItem('user_logged_in', 'false');
      self.router.navigate(['/login']);

      //window.location.reload();
    }
  }

  toChat() {
    this.router.navigate(['/chat']);
    this.service.user = false;
  }

  toSearchHome() {
    this.service.quickSearch = !this.service.quickSearch;
    this.router.navigate(['/home']);
  }

  getBlockedUsers() {
    firebase.database().ref('blockedUsers/').orderByChild('blockedBy').equalTo(localStorage.getItem('uid'))
      .once('value', (snapshot) => {
        var users = snapshot.val();
        for (var key in users) {
          var user = users[key];
          if (user) {
            this.blockedUser.push(user.blockedTo);
          }
        }
        localStorage.setItem('blockedUsers', JSON.stringify(this.blockedUser));
      })
  }

  CountMsgNotRead() {
    for (var key in this.allChats) {
      var chat = this.allChats[key];
      for (var key1 in chat.messages) {
        var message = chat.messages[key1];
        if (message.checked == "false" && message.senderId != localStorage.getItem('uid')) {
          this.badgeCount++;
        }
      }
    }
    if (this.badgeCount > 0) {

      if (document.getElementById('badgeCountTotal')) {
        document.getElementById('badgeCountTotal').innerText = this.badgeCount.toString();
      }
      return this.badgeCount;
    } else {
      if (document.getElementById('badgeCountTotal')) {
        document.getElementById('badgeCountTotal').setAttribute("hidden", 'true');
      }

    }


  }

  getMyChats() {
    var self = this;
    firebase.database().ref().child('chats')
      .once('value', (snapshot) => {
        var chats = snapshot.val();
        for (var key in chats) {
          var chat = chats[key];
          chat.key = key;
          var chatKey = chat.key;
          var parts = chatKey.split("-");
          var part1 = parts[0];
          var part2 = parts[1];
          if (part1 == localStorage.getItem('uid') || part2 == localStorage.getItem('uid')) {
            if (localStorage.getItem('uid') == part1) {
              chat.recipientId = part2;
            }
            if (localStorage.getItem('uid') == part2) {
              chat.recipientId = part1;
            }
            if (!this.checkMsgBlocked(chat.recipientId)) {
              if (!chat.status) {
                self.allChats.push(chat);
              }
              else {
                if (chat.status.length == 1) {
                  var checkArray = [];
                  if (chat.status[0].deletedBy == localStorage.getItem('uid')) {
                    for (var key in chat.messages) {
                      var message = chat.messages[key];
                      if (this.checkMsgTimestamp(message, chat.status)) {
                        checkArray.push(true);
                      }
                      else {
                        checkArray.push(false);
                      }
                    }
                    if (checkArray.includes(true)) {
                      self.allChats.push(chat);
                    }
                  }
                  else {
                    self.allChats.push(chat);
                  }
                }
                else {
                  var checkArray = [];
                  if (chat.status[0].deletedBy == localStorage.getItem('uid')) {
                    for (var key in chat.messages) {
                      var message = chat.messages[key];
                      if (this.checkMsgTimestamp(message, chat.status)) {
                        checkArray.push(true);
                      }
                      else {
                        checkArray.push(false);
                      }
                    }
                    if (checkArray.includes(true)) {
                      self.allChats.push(chat);
                    }
                  }
                  else {
                    for (var key in chat.messages) {
                      var message = chat.messages[key];
                      if (this.checkMsgTimestamp(message, chat.status)) {
                        checkArray.push(true);
                      }
                      else {
                        checkArray.push(false);
                      }
                    }
                    if (checkArray.includes(true)) {
                      self.allChats.push(chat);
                    }
                  }
                }
              }
            }
          }
        }
        if (!this.service.user && this.allChats.length == 0) {
          // this.showEmptyChat = true;
        }
        else {
          this.CountMsgNotRead();
        }
      });
  }

  checkMsgTimestamp(chat, status) {
    if (status[0].deletedBy == localStorage.getItem('uid')) {
      if (chat.timestamp > status[0].timestamp) {
        return true;
      }
    }
    else if (chat.timestamp > status[1].timestamp) {
      return true;
    }
  }

  checkMsgBlocked(recipientId) {
    for (var i in this.blockedUser) {
      var blockedId = this.blockedUser[i];
      if (recipientId == blockedId) { return true; }
    }
  }
}
