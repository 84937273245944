import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pre-header',
  templateUrl: './pre-header.component.html',
  styleUrls: ['./pre-header.component.css']
})
export class PreHeaderComponent implements OnInit {


public language :string;
  constructor(public route: ActivatedRoute,private _translate 	  : TranslateService, public router: Router)   
  { }

  ngOnInit() {  
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else { this.language = this._translate.currentLang; }
  }
}
