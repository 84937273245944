import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  ServerUrl = 'http://localhost/';
  errorData: {};

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };
  constructor(private http:  HttpClient) { }
  contacter(formdata): Observable<Object> {
    let url = this.ServerUrl + 'email/email.php';

    let postData = new FormData();
     
   

     var myData =JSON.stringify(formdata);
 
    console.log(myData);
    return this.http.post(url,myData , {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Access-Control-Allow-Origin', '*')
    });   
  }


  sendEmail(formdata): Observable<Object> { 
    return this.http.post(this.ServerUrl , formdata, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong.

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

  ////////////////////
  gg(argparam) {
    return this.http.post('http://localhost:4200/email/', argparam,this.httpOptions);
    //.map(res => res.json())
   // .catch(this._errorHandler);


   
  }


}
