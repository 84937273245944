import { Component } from '@angular/core';
import { PresenceService } from './presence.service';
import {TranslateService} from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  public language: string="";
  constructor(public presence: PresenceService,public _translate: TranslateService) {
    _translate.addLangs(['en','fr','de-CH','es']); 
    
    const browserLang= _translate.getBrowserLang();
  


    if ( localStorage.getItem('lg')) {    
      
      this.language =localStorage.getItem('lg');
      _translate.use( localStorage.getItem('lg'));
      this._translate.setDefaultLang( this.language);
     
    }else{
      
      localStorage.setItem('lg', browserLang);
      this._translate.setDefaultLang('en');
      this.language =localStorage.getItem('lg');

     // _translate.use(browserLang.match(/en|fr/)? browserLang : browserLang.match(/de-CH|es/)? browserLang :'en');
     
       _translate.use(this.language);
    }

  /*  this._initTranslate();*/
     this.onActivate(event);
  }
  onActivate(event) {
    window.scroll(0, 0);
  }
  public changeLanguage(): void {

    localStorage.setItem('lg', this.language);
    this._translate.setDefaultLang(this.language);
    
      this._translate.use(this.language);
     
  }
  private _initTranslate()
  {
     // Set the default language for translation strings, and the current language.
     this._translate.setDefaultLang('fr');


     if (this._translate.getBrowserLang() !== undefined)
     {   
         this._translate.use(this._translate.getBrowserLang());        
     }
     else
     {
         this._translate.use('fr'); // Set your language here
     }
  }
}
