import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { DataCollectionsService } from '../data-collections.service';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { PresenceService } from '../presence.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  public language: string;
  public showPop: any;
  public allUsers: Array<any> = [];
  public fetchedUsers: Array<any> = [];
  public loading: any = false;
  public showMsg = false;
  public showInput = false;
  public showInputEmail = false;
  public showRangebar = false;
  public totalUsers: Array<any> = [];
  public blockedUser: Array<any> = [];
  public email_serach: any = "";

  public searchFilters = {
    interestedIn: "Doesn’t matter",
    handyPlus: "Doesn’t matter",
    city: "",
    minValue: 18,
    maxValue: 50,
    email_search: "",
  }

  options: Options = {
    floor: 18,
    ceil: 65,
  };

  constructor(public service: DataCollectionsService,
    private presence: PresenceService,
    private afAuth: AngularFireAuth,
    public router: Router,
    private _translate: TranslateService) {

    if (localStorage.getItem('popupSeen') == 'false') {
      this.showPop = true;
    }
  }

  ngOnInit() {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else { this.language = this._translate.currentLang; }

    this.getBlockedUsers();
    this.getAllUsers();
  }
  desactiver() {
    console.log("1 essai : ", this.afAuth.user.subscribe());
    this.afAuth.user.toPromise().then(result => {
      console.log("2 essai : ", result);
    });
    this.afAuth.user.forEach(element => {
      console.log("3 essai : ", element)
    });



  }

  getBlockedUsers() {
    firebase.database().ref('blockedUsers/').orderByChild('blockedBy').equalTo(localStorage.getItem('uid'))
      .once('value', (snapshot) => {
        var users = snapshot.val();
        for (var key in users) {
          var user = users[key];
          if (user) {
            this.blockedUser.push(user.blockedTo);
          }
        }
        localStorage.setItem('blockedUsers', JSON.stringify(this.blockedUser));
      })
  }

  getAllUsers() {
    var self = this;
    self.loading = true;
    self.showMsg = false;
    firebase.database().ref().child('users')
      .once('value', function (snapshot) {
        var users = snapshot.val();
        for (var key in users) {
          var user = users[key];

          if (!self.blockedUser.includes(user.uid)) {
            var dob = new Date(user.dob);
            var timeDiff = Math.abs(Date.now() - dob.getTime());
            user.userAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
            if (user.uid != localStorage.getItem('uid')) {
              self.allUsers.push(user);
              self.totalUsers = self.allUsers;
            }
          }
        }
        self.loading = false;
      });
  }

  userDetails(uid) {
    this.router.navigate(['/other-profile', uid]);
  }

  applyFilters() {
    this.loading = true;
    this.fetchedUsers = this.totalUsers;
    this.allUsers = [];
    for (var i = 0; i < this.fetchedUsers.length; i++) {
      var dob = new Date(this.fetchedUsers[i].dob);
      var timeDiff = Math.abs(Date.now() - dob.getTime());
      debugger;
      this.fetchedUsers[i].userAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      if (this.fetchedUsers[i].interestedIn == this.searchFilters.interestedIn ||
        this.searchFilters.interestedIn == "Doesn’t matter") {


        if (this.fetchedUsers[i].myUnique == this.searchFilters.handyPlus ||
          this.searchFilters.handyPlus == "Doesn’t matter") {
          if (this.fetchedUsers[i].userAge >= this.searchFilters.minValue &&
            this.fetchedUsers[i].userAge <= this.searchFilters.maxValue) {
            this.allUsers.push(this.fetchedUsers[i]);
          }
        }
      }
      if (this.searchFilters.city != "") {
        this.allUsers = this.fetchedUsers.filter((fetchedUsers) => {
          return (fetchedUsers.homeTown.toLowerCase().indexOf(this.searchFilters.city.toLowerCase()) > -1);
        })
      }


      if (this.searchFilters.email_search != '') {
        this.allUsers = this.fetchedUsers.filter((fetchedUsers) => {
          return (fetchedUsers.email == this.searchFilters.email_search);
        })
      }

      this.loading = false;
      if (this.allUsers.length == 0) {
        this.showMsg = true;
      }
      else {
        this.showMsg = false;
      }
    }

  }

  clearFilters() {
    this.searchFilters = {
      interestedIn: "Doesn’t matter",
      handyPlus: "Doesn’t matter",
      city: "",
      minValue: 18,
      maxValue: 50,
      email_search: "",
    };
    this.email_serach = "";
    this.showInput = false;
    this.showInputEmail = false;
    this.showMsg = false;
    this.showRangebar = false;
    this.allUsers = this.totalUsers;
  }

  popupSeen() {
    localStorage.setItem('popupSeen', 'true');
  }
}
