import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class DataCollectionsService {

  public quickSearch = false;
  public user :any= {};
  public nickName:any;
  public smile:any=false;

  constructor() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    firebase.database().ref().child('users/' + localStorage.getItem('uid'))
      .once('value', (snapshot) => {
        var user = snapshot.val();
        if (user) {
          
          localStorage.setItem('currentUser', JSON.stringify(user));
        }
      });
  }
}
