import { BrowserModule } from '@angular/platform-browser';

import { NgModule, ErrorHandler } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import * as firebase from 'firebase';
//import * as admin from 'firebase-admin';
//import * as functions from 'firebase-functions';


import { NgxLoadingModule } from 'ngx-loading';
import { EmojiPickerModule } from 'ng-emoji-button-picker';

 
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { PreHomeComponent } from './pre-home/pre-home.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { PreHeaderComponent } from './pre-header/pre-header.component';
import { WelcomeMsgComponent } from './welcome-msg/welcome-msg.component';
import { DataCollectionsService } from '../app/data-collections.service';

import { MyProfileComponent } from './my-profile/my-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { OtherProfileComponent } from './other-profile/other-profile.component';
import { ChatComponent } from './chat/chat.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { AuthService } from '../app/auth.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
//import { RxSpeechRecognitionService } from '@kamiazya/ngx-speech-recognition';
import { AngularFireDatabase } from '@angular/fire/database';
import { PresenceService } from './presence.service';
import { UserStatusComponent } from './user-status/user-status.component';
import { FooterComponent } from "./footer/footer.component";


import { TranslateModule, TranslateLoader, TranslateService,TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {   MatIconModule } from '@angular/material';

import { MatBadgeModule } from '@angular/material';
import {   MatButtonModule } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { EmailService } from './email.service';
import { environment } from 'src/environments/environment';

/*import {  } from '@angular/material/icon';
import { } from '@angular/material/button';*/
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
// AoT requires an exported function for factories

// import { RxSpeechRecognitionService } from '@kamiazya/ngx-speech-recognition';
// import { SpeechRecognitionService } from 'node_modules_archive/@kamiazya/ngx-speech-recognition/lib/service/speech-recognition.service';
// import { SpeechRecognitionCommon } from '@kamiazya/ngx-speech-recognition/lib/service/speech-recognition.common';

//import * as admin from 'firebase-admin';

// Your web app's Firebase configuration
//const admin = require('firebase-admin');
//admin.initializeApp(firebaseConfig);
// The Cloud Functions for Firebase SDK to create Cloud Functions and setup triggers.
//const functions = require('firebase-functions');
// The Firebase Admin SDK to access the Firebase Realtime Database.
//const admin = require('firebase-admin');
//admin.initializeApp(); 
//const functions = require('firebase-functions');
//const db = admin.firestore();
//var admin = require("firebase-admin");

/*
var firebaseConfig = {
  apiKey: "AIzaSyDTkBw2kNhcgZ0CiNHfWlA43bVgfEE1XQE",
  authDomain: "myhandyplus-66863.firebaseapp.com",
  databaseURL: "https://myhandyplus-66863.firebaseio.com",
  projectId: "myhandyplus-66863",
  storageBucket: "myhandyplus-66863.appspot.com",
  messagingSenderId: "1099099676479",
  appId: "1:1099099676479:web:138f26cbbd0be309b2bd8a",
  measurementId: "G-DHJ6THFXRM"
};*/

var firebaseConfig_Archive = {
  apiKey: "AIzaSyCiAROumSoi1pp15z5eRlsdUdHQGIhPF7s",
  authDomain: "myhandyplus.firebaseapp.com",
  databaseURL: "https://myhandyplus.firebaseio.com",
  projectId: "myhandyplus",
  storageBucket: "myhandyplus.appspot.com",
  messagingSenderId: "206740733097",
  appId: "1:206740733097:web:41cdddece29972ac"
};
// Initialize Firebas
//firebase.initializeApp(environment.firebase);


const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'pre-home', component: PreHomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'welcome-msg', component: WelcomeMsgComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'edit-profile', component: EditProfileComponent },
  { path: 'other-profile/:id', component: OtherProfileComponent, data: { userObject: 'userObject' } },
  { path: 'chat', component: ChatComponent },
  { path: 'favorites', component: FavoritesComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'terms-conditions', component: TermsConditionComponent },

  { path: '', redirectTo: '/pre-home', pathMatch: 'full' },
];
/*
@NgModule({
  
  exports: [
    //MatBadgeModule,
    //MatIconModule,
    //MatButtonModule   
  //  TranslateModule,// BrowserModule,
   
  ]
})*/
//export class MaterialModule {}

@NgModule({
  declarations: [
   
    AppComponent,
    HeaderComponent,
    HomeComponent,
    PreHomeComponent,
    LoginComponent,
    SignupComponent,
    PreHeaderComponent,
    WelcomeMsgComponent,
    MyProfileComponent,
    EditProfileComponent,
    OtherProfileComponent,
    ChatComponent,
    FavoritesComponent,
    ResetPasswordComponent,
    TermsConditionComponent,
    UserStatusComponent,
    FooterComponent,
  
    
  ],
  imports: [
   // MaterialModule,  
   
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),

    BrowserModule,
    //AngularFireModule.initializeApp(firebaseConfig),   
    AngularFireModule.initializeApp(environment.firebase),
    Ng5SliderModule,
    FormsModule,
    NgxLoadingModule.forRoot({}),
    ReactiveFormsModule,
    EmojiPickerModule,
    ScrollToModule.forRoot(),
    AngularFireAuthModule,  
    
  RouterModule.forRoot(appRoutes),
   /*   RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
  
      RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64]
    })*/
   
   
  ],
  providers: [ 
    DataCollectionsService,
    AuthService,
    PresenceService,
    AngularFireDatabase,
    AngularFirestore ,
    EmailService,TranslateService,
   


 
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }