import { Component, OnInit } from '@angular/core';
import { DataCollectionsService } from '../data-collections.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { TranslateService } from '@ngx-translate/core';
//import * as admin from 'firebase-admin';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  public language :string;
  public txt_editProfile_uploadImge :string;
  public txt_registration_city_placeholder :string;

  public txt_editProfile_myUnique_1: string; public txt_editProfile_myUnique_2: string; public txt_editProfile_myUnique_3: string;
  public txt_editProfile_myUnique_4: string; public txt_editProfile_myUnique_5: string; public txt_editProfile_myUnique_6: string;
  public txt_editProfile_myUnique_7: string; public txt_editProfile_myUnique_8: string; public txt_editProfile_myUnique_9: string;
  public txt_editProfile_myUnique_10: string; public txt_editProfile_myUnique_11: string; public txt_editProfile_myUnique_12: string;
  public txt_editProfile_myUnique_13: string; public txt_editProfile_myUnique_14: string; public txt_editProfile_myUnique_15: string;
  public txt_editProfile_myUnique_16: string; public txt_editProfile_myUnique_17: string; public txt_editProfile_myUnique_18: string;
  public txt_editProfile_myUnique_19: string; public txt_editProfile_myUnique_20: string; public txt_editProfile_myUnique_21: string;
  public txt_editProfile_myUnique_22: string; public txt_editProfile_myUnique_23: string; public txt_editProfile_myUnique_24: string;
  public txt_editProfile_myUnique_25: string;

  public txt_myProfile_status_op0 :string;
  public txt_editProfile_myUnique_1_searching: string; public txt_editProfile_myUnique_2_searching: string; public txt_editProfile_myUnique_3_searching: string;
  public txt_editProfile_myUnique_4_searching: string; public txt_editProfile_myUnique_5_searching: string; public txt_editProfile_myUnique_6_searching: string;
  public txt_editProfile_myUnique_7_searching: string; public txt_editProfile_myUnique_8_searching: string; public txt_editProfile_myUnique_9_searching: string;
  public txt_editProfile_myUnique_10_searching: string; public txt_editProfile_myUnique_11_searching: string; public txt_editProfile_myUnique_12_searching: string;
  public txt_editProfile_myUnique_13_searching: string; public txt_editProfile_myUnique_14_searching: string; public txt_editProfile_myUnique_15_searching: string;
  public txt_editProfile_myUnique_16_searching: string; public txt_editProfile_myUnique_17_searching: string; public txt_editProfile_myUnique_18_searching: string;
  public txt_editProfile_myUnique_19_searching: string; public txt_editProfile_myUnique_20_searching: string; public txt_editProfile_myUnique_21_searching: string;
  public txt_editProfile_myUnique_22_searching: string; public txt_editProfile_myUnique_23_searching: string; public txt_editProfile_myUnique_24_searching: string;
  public txt_editProfile_myUnique_25_searching: string;

  public profileImage: any;
  public profileBg: any;
  public loading = false;
  public newFile = false;
  public newBg = false;

  
  public edit=false;
  public editPhoto=false;
  public myTrait: any;
  public myHobby: any;
  public goingOut :any;
  
  public myMovie :any;
  public myMusic :any;
  public myBook :any;
  public user: any = {};

  public uid: any;
  txt_editProfile_myBookplaceholder:string;
  constructor(public service: DataCollectionsService,private _translate: TranslateService,
    public router: Router) {
      this.user = this.service.user;
     // this.uid = localStorage.getItem("uid");
  
    //  this.getUserData();
    
  
    //debugger
   // this.user = this.service.user;
    console.log("this.user in edit init :" +this.user);

    if (!this.user.sexe) {
      this.user.sexe = "men";
    }
    if (!this.user.status) {
      this.user.status = "notMatter";
    }

    if (!this.user.handyplus) {
      this.user.handyplus = "helping";
    }
  if (!this.user.lifeStyle) {
    this.user.lifeStyle ={}
    }
/*          this.user.lifeStyle= {
        myTraits: {},
        myHobbies: {},
        goingOuts: {},
        myMovies:{},
        musicList:{},
        myBooks: {},
    }
    }*/
    
  if (!this.user.profileUrl) {
      this.user.profileUrl = "";
    }
   if (!this.user.searchingFor) {
      this.user.searchingFor = {
        status: "notMatter",
        handyplus: "Doesn’t matter"
      };
    }
  }
  getUserData() {
    var self = this;
    // self.user.lifeStyle = {  };
 //  self.user.lifeStyle= {
    // myTraits:{},
    //  goingOuts: ["cc"]
     /* myHobbies: {},
      goingOuts: {},
      myMovies:{},
      musicList:{},
      myBooks: {},*/
 // };
    
   // self.loading = true;
   // debugger;
    firebase.database().ref().child('users/' + self.uid)
      .once('value', (snapshot) => {
    //   debugger;
        var user = snapshot.val();
        console.log("user is in edit: ", user);
        self.user = user;
        self.user.userAge = user.userAge;
        self.user.profileUrl = user.profileUrl;
      
        var dob = new Date(self.user.dob);
        var timeDiff = Math.abs(Date.now() - dob.getTime());
        self.user.userAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
     //   self.loading = false;
      });
   
  }
  ngOnInit() {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    }else
    {this.language = this._translate.currentLang;}
   
    
  
  }
  /*
enableUser(){
  admin.auth().updateUser(this.user.uid, {
  
    disabled: true
  })
    .then(function(userRecord) {
      // See the UserRecord reference doc for the contents of userRecord.
      console.log('Successfully updated user', userRecord.toJSON());
    })
    .catch(function(error) {
      console.log('Error updating user:', error);
    });
}*/
  addLifeStyle(arrayName, value) {
   
    if (value) {
    //  alert("add value :"+ value)
      if (!this.user.lifeStyle[arrayName]) {
        alert("add mytraits :"+ arrayName+"  "+ value)
        this.user.lifeStyle[arrayName] = [];
      }
      alert( " this.user.lifeStyle : "+this.user.lifeStyle);
      this.user.lifeStyle[arrayName].push(value);
    }
  }

  removeLifeStyle(arrayName, index) {
    this.user.lifeStyle[arrayName].splice(index, 1);
  }

  saveProfile() {
    this.loading = true;
    if (this.newFile) {
      this.saveImage();
    } if (this.newBg) {
      this.saveBg();
    }
    else {
      this.uploadData();
    }
  }

  saveImage() {
    var self = this;
    let storageRef = firebase.storage().ref();
    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    storageRef.child('profileImages/' + filename).put(self.profileImage, metadata)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.user.profileUrl = downloadURL;
          self.uploadData();
        });
      });
  }

  saveBg() {
    var self = this;
    let storageRef = firebase.storage().ref();
    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    storageRef.child('profileImages/' + filename).put(self.profileBg, metadata)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.user.profileHeader = downloadURL;
          self.uploadData();
        });
      });
  }

  uploadData() {
    var self = this;
    var updates = {};
    updates['/users/' + self.user.uid + "/" + "profileUrl"] = self.user.profileUrl ? self.user.profileUrl : "";
    //updates['/users/' + self.user.uid + "/" + "profileHeader"] = self.user.profileHeader ? self.user.profileHeader : "";
    updates['/users/' + self.user.uid + "/" + "nickName"] = self.user.nickName ? self.user.nickName : "";
    updates['/users/' + self.user.uid + "/" + "homeTown"] = self.user.homeTown ? self.user.homeTown : "";
    updates['/users/' + self.user.uid + "/" + "description"] = self.user.description ? self.user.description : "";

    updates['/users/' + self.user.uid + "/" + "sexe"] = self.user.sexe ? self.user.sexe : "";
    updates['/users/' + self.user.uid + "/" + "status"] = self.user.status ? self.user.status : "";
    // updates['/users/' + self.user.uid + "/" + "children"] = self.user.children ? self.user.children : "";
    updates['/users/' + self.user.uid + "/" + "height"] = self.user.height ? self.user.height : "";
    updates['/users/' + self.user.uid + "/" + "eyes"] = self.user.eyes ? self.user.eyes : "";
    updates['/users/' + self.user.uid + "/" + "hair"] = self.user.hair ? self.user.hair : "";
    updates['/users/' + self.user.uid + "/" + "silhouette"] = self.user.silhouette ? self.user.silhouette : "";
    updates['/users/' + self.user.uid + "/" + "handyplus"] = self.user.handyplus ? self.user.handyplus : "";
    updates['/users/' + self.user.uid + "/" + "myUnique"] =self.user.handyplus ? self.user.handyplus : "";

    updates['/users/' + self.user.uid + "/" + "searchingFor"] = self.user.searchingFor ? self.user.searchingFor : "";
    updates['/users/' + self.user.uid + "/" + "lifeStyle"] = self.user.lifeStyle ? self.user.lifeStyle : "";

    firebase.database().ref().update(updates).then(() => {
      self.loading = false;
     // if (self.user.profileUrl) {
        localStorage.setItem('profileUrl', self.user.profileUrl);
     // }
      self.router.navigate(['/my-profile']);
    });
  }
  onSelectFile(event) {
    let reader = new FileReader();
    reader.onload = function(){
      let output: any = document.getElementById('txt_editProfile_uploadImge');
      output.src = reader.result;
      
    }
    if(event.target.files[0]){
      reader.readAsDataURL(event.target.files[0]);
      
     
    }  
}

  onChangeFile(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.profileImage = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    this.newFile = true; 
    reader.onload = (_event) => {  
     // let output: any = document.getElementById('txt_editProfile_uploadImge');
     // output.src = reader.result;  
     // this.user.profileUrl =  output.src ;
      this.user.profileUrl = reader.result;
    }    
  }

  onChangeCover(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.profileBg = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    this.newBg = true;
    reader.onload = (_event) => {
      this.user.profileHeader = reader.result;
    }
  }

  getUrl() {
    return "url(" + this.user.profileHeader + ") center no-repeat";
  }
/**translation */
public changeLanguage(): void {

  localStorage.setItem('lg', this.language);



  this._translate.use(this.language);
  this._initialiseTranslation();
}

private _initialiseTranslation(): void {
  setTimeout(() => {
   // if (this.txt_editProfile_uploadImge == null) {
      this.txt_editProfile_uploadImge = this._translate.instant("edit-profile.TXTEditProfileBtnUploadImage");
  //  }

    if (this.txt_registration_city_placeholder == null) {
      this.txt_registration_city_placeholder = this._translate.instant("sign-up.TXTRegistrationCityPlaceholder");
    }
    document.getElementById('txt_registration_city_placeholder').setAttribute("placeholder",
    this._translate.instant("sign-up.TXTRegistrationCityPlaceholder"));
    document.getElementById('txt_editProfile_Doesnt_matter1').innerText = 
    this._translate.instant("home.TXTHomeDoesntMatter");
   this.txt_myProfile_status_op0 = this._translate.instant("home.TXTHomeDoesntMatter");
    if (this.txt_editProfile_myUnique_1 == null) {
      this.txt_editProfile_myUnique_1 = this._translate.instant("sign-up.TXTRegistrationMyUnique1");
    }
    if (this.txt_editProfile_myUnique_2 == null) {
      this.txt_editProfile_myUnique_2 = this._translate.instant("sign-up.TXTRegistrationMyUnique2");
    }
    if (this.txt_editProfile_myUnique_3 == null) {
      this.txt_editProfile_myUnique_3 = this._translate.instant("sign-up.TXTRegistrationMyUnique3");
    }
    if (this.txt_editProfile_myUnique_4 == null) {
      this.txt_editProfile_myUnique_4 = this._translate.instant("sign-up.TXTRegistrationMyUnique4");
    }
    if (this.txt_editProfile_myUnique_5== null) {
      this.txt_editProfile_myUnique_5 = this._translate.instant("sign-up.TXTRegistrationMyUnique5");
    }
    if (this.txt_editProfile_myUnique_6 == null) {
      this.txt_editProfile_myUnique_6 = this._translate.instant("sign-up.TXTRegistrationMyUnique6");
    }
    if (this.txt_editProfile_myUnique_7 == null) {
      this.txt_editProfile_myUnique_7 = this._translate.instant("sign-up.TXTRegistrationMyUnique7");
    }
    if (this.txt_editProfile_myUnique_8 == null) {
      this.txt_editProfile_myUnique_8 = this._translate.instant("sign-up.TXTRegistrationMyUnique8");
    }
    if (this.txt_editProfile_myUnique_9 == null) {
      this.txt_editProfile_myUnique_9= this._translate.instant("sign-up.TXTRegistrationMyUnique9");
    }


    if (this.txt_editProfile_myUnique_10 == null) {
      this.txt_editProfile_myUnique_10 = this._translate.instant("sign-up.TXTRegistrationMyUnique10");
    }     
    if (this.txt_editProfile_myUnique_11 == null) {
      this.txt_editProfile_myUnique_11 = this._translate.instant("sign-up.TXTRegistrationMyUnique11");
    }
    if (this.txt_editProfile_myUnique_12 == null) {
      this.txt_editProfile_myUnique_12 = this._translate.instant("sign-up.TXTRegistrationMyUnique12");
    }
    if (this.txt_editProfile_myUnique_13 == null) {
      this.txt_editProfile_myUnique_13 = this._translate.instant("sign-up.TXTRegistrationMyUnique13");
    }
    if (this.txt_editProfile_myUnique_14 == null) {
      this.txt_editProfile_myUnique_14 = this._translate.instant("sign-up.TXTRegistrationMyUnique14");
    }
    if (this.txt_editProfile_myUnique_15== null) {
      this.txt_editProfile_myUnique_15 = this._translate.instant("sign-up.TXTRegistrationMyUnique15");
    }
    if (this.txt_editProfile_myUnique_16 == null) {
      this.txt_editProfile_myUnique_16 = this._translate.instant("sign-up.TXTRegistrationMyUnique16");
    }
    if (this.txt_editProfile_myUnique_17 == null) {
      this.txt_editProfile_myUnique_17 = this._translate.instant("sign-up.TXTRegistrationMyUnique17");
    }
    if (this.txt_editProfile_myUnique_18 == null) {
      this.txt_editProfile_myUnique_18 = this._translate.instant("sign-up.TXTRegistrationMyUnique18");
    }
    if (this.txt_editProfile_myUnique_19 == null) {
      this.txt_editProfile_myUnique_19= this._translate.instant("sign-up.TXTRegistrationMyUnique19");
    }

    if (this.txt_editProfile_myUnique_20 == null) {
      this.txt_editProfile_myUnique_20 = this._translate.instant("sign-up.TXTRegistrationMyUnique20");
    }
    if (this.txt_editProfile_myUnique_21== null) {
      this.txt_editProfile_myUnique_21 = this._translate.instant("sign-up.TXTRegistrationMyUnique21");
    }
    if (this.txt_editProfile_myUnique_22 == null) {
      this.txt_editProfile_myUnique_22 = this._translate.instant("sign-up.TXTRegistrationMyUnique22");
    }
    if (this.txt_editProfile_myUnique_23 == null) {
      this.txt_editProfile_myUnique_23 = this._translate.instant("sign-up.TXTRegistrationMyUnique23");
    }
    if (this.txt_editProfile_myUnique_24 == null) {
      this.txt_editProfile_myUnique_24 = this._translate.instant("sign-up.TXTRegistrationMyUnique24");
    }
    if (this.txt_editProfile_myUnique_25 == null) {
      this.txt_editProfile_myUnique_25 = this._translate.instant("sign-up.TXTRegistrationMyUnique25");   
    }

    if (this.txt_editProfile_myUnique_1_searching == null) {
      this.txt_editProfile_myUnique_1_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique1");
    }
    if (this.txt_editProfile_myUnique_2 == null) {
      this.txt_editProfile_myUnique_2_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique2");
    }
    if (this.txt_editProfile_myUnique_3_searching == null) {
      this.txt_editProfile_myUnique_3_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique3");
    }
    if (this.txt_editProfile_myUnique_4_searching == null) {
      this.txt_editProfile_myUnique_4_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique4");
    }
    if (this.txt_editProfile_myUnique_5_searching== null) {
      this.txt_editProfile_myUnique_5_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique5");
    }
    if (this.txt_editProfile_myUnique_6_searching == null) {
      this.txt_editProfile_myUnique_6_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique6");
    }
    if (this.txt_editProfile_myUnique_7_searching == null) {
      this.txt_editProfile_myUnique_7_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique7");
    }
    if (this.txt_editProfile_myUnique_8_searching == null) {
      this.txt_editProfile_myUnique_8_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique8");
    }
    if (this.txt_editProfile_myUnique_9_searching == null) {
      this.txt_editProfile_myUnique_9_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique9");
    }


    if (this.txt_editProfile_myUnique_10_searching == null) {
      this.txt_editProfile_myUnique_10_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique10");
    }     
    if (this.txt_editProfile_myUnique_11_searching == null) {
      this.txt_editProfile_myUnique_11_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique11");
    }
    if (this.txt_editProfile_myUnique_12_searching == null) {
      this.txt_editProfile_myUnique_12_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique12");
    }
    if (this.txt_editProfile_myUnique_13_searching == null) {
      this.txt_editProfile_myUnique_13_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique13");
    }
    if (this.txt_editProfile_myUnique_14_searching == null) {
      this.txt_editProfile_myUnique_14_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique14");
    }
    if (this.txt_editProfile_myUnique_15_searching== null) {
      this.txt_editProfile_myUnique_15_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique15");
    }
    if (this.txt_editProfile_myUnique_16_searching == null) {
      this.txt_editProfile_myUnique_16_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique16");
    }
    if (this.txt_editProfile_myUnique_17_searching == null) {
      this.txt_editProfile_myUnique_17_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique17");
    }
    if (this.txt_editProfile_myUnique_18_searching == null) {
      this.txt_editProfile_myUnique_18_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique18");
    }
    if (this.txt_editProfile_myUnique_19_searching == null) {
      this.txt_editProfile_myUnique_19_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique19");
    }

    if (this.txt_editProfile_myUnique_20_searching == null) {
      this.txt_editProfile_myUnique_20_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique20");
    }
    if (this.txt_editProfile_myUnique_21_searching== null) {
      this.txt_editProfile_myUnique_21_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique21");
    }
    if (this.txt_editProfile_myUnique_22_searching == null) {
      this.txt_editProfile_myUnique_22_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique22");
    }
    if (this.txt_editProfile_myUnique_23_searching == null) {
      this.txt_editProfile_myUnique_23_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique23");
    }
    if (this.txt_editProfile_myUnique_24_searching == null) {
      this.txt_editProfile_myUnique_24_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique24");
    }
    if (this.txt_editProfile_myUnique_25_searching == null) {
      this.txt_editProfile_myUnique_25_searching = this._translate.instant("sign-up.TXTRegistrationMyUnique25");
   
    }



 
  /*  

   if (this.txt_myProfile_status == null) {
      this.txt_myProfile_status = this._translate.instant("my-profile.TXTMyProfileStatus");
    }
     if (this.txt_myProfile_status_searching == null) {
      this.txt_myProfile_status_searching = this._translate.instant("my-profile.TXTMyProfileStatus");
    }
    if (this.txt_myProfile_city_searching == null) {
      this.txt_myProfile_city_searching = this._translate.instant("home.TXTHomeCity");
    }
    if (this.txt_myProfile_handyplus == null) {
      this.txt_myProfile_handyplus = this._translate.instant("my-profile.TXTMyProfileHandyPlus");
    }
    if (this.txt_myProfile_height == null) {
      this.txt_myProfile_height =  this._translate.instant("my-profile.TXTMyProfileHeight");
    }

    if (this.txt_myProfile_eyes == null) {
      this.txt_myProfile_eyes =  this._translate.instant("my-profile.TXTMyProfileEyes");
    }

    if (this.txt_myProfile_hair == null) {
      this.txt_myProfile_hair =  this._translate.instant("my-profile.TXTMyProfileHair");
    }
    if (this.txt_myProfile_silhouete == null) {
      this.txt_myProfile_silhouete =  this._translate.instant("my-profile.TXTMyProfileSilhouette");
    }
    if (this.txt_myProfile_handyplus_searching == null) {
      this.txt_myProfile_handyplus_searching = this._translate.instant("my-profile.TXTMyProfileHandyPlus");
    }
    if (this.txt_myProfile_lifestyle_mytraits == null) {
      this.txt_myProfile_lifestyle_mytraits = this._translate.instant("my-profile.TXTMyProfileLifestyleMytraits");
    }
    if (this.txt_myProfile_lifestyle_myhobbies == null) {
      this.txt_myProfile_lifestyle_myhobbies = this._translate.instant("my-profile.TXTMyProfileLifestyleMyhobbies");
    }    
    if (this.txt_myProfile_lifestyle_goingout == null) {
      this.txt_myProfile_lifestyle_goingout = this._translate.instant("my-profile.TXTMyProfileLifestyleGoingOut");
    }
    if (this.txt_myProfile_lifestyle_movies == null) {
      this.txt_myProfile_lifestyle_movies = this._translate.instant("my-profile.TXTMyProfileLifestyleMovies");
    }
    if (this.txt_myProfile_lifestyle_music == null) {
      this.txt_myProfile_lifestyle_music = this._translate.instant("my-profile.TXTMyProfileLifestyleMusic");
    }
    if (this.txt_myProfile_lifestyle_books == null) {
      this.txt_myProfile_lifestyle_books =  this._translate.instant("my-profile.TXTMyProfileLifestyleBooks");
    }*/
  }, 250);


}

}
