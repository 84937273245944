import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { TranslateService } from '@ngx-translate/core';
import { DataCollectionsService } from '../data-collections.service';
// import { DataCollectionsService } from 'Archive/src/app/data-collections.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {
  public language: string;
  public loading = false;
  public myFavourites: Array<any> = [];
  public favouritesBy: Array<any> = [];
  public allUsers: Array<any> = [];
  public uid: any;
  public currentUser: any = {};
  public loopLen: any;
  public modalImg: any;
  public user: any;
  public blockedUser: Array<any> = [];

  constructor(
    public router: Router,
    public _translate: TranslateService,
    public service: DataCollectionsService) {

    this.uid = localStorage.getItem("uid");

    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else {
      this.language = this._translate.currentLang;
      this._translate.use(localStorage.getItem('lg'));
    }
  }

  ngOnInit() {
    var retrievedObject = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(retrievedObject);
    this.getFavourites();
  }

  getUserData() {
    var self = this;
    self.loading = true;
    firebase.database().ref().child('users/' + self.uid)
      .once('value', function (snapshot) {
        var user = snapshot.val();
        if (user) {
          self.currentUser = user;
        }
        self.loading = false;
      });

      // PSE
      this.ngOnInit();
      // PSE
  }

  getBlockedUsers() {
    firebase.database().ref('blockedUsers/').orderByChild('blockedBy').equalTo(localStorage.getItem('uid'))
      .once('value', (snapshot) => {
        var users = snapshot.val();
        for (var key in users) {
          var user = users[key];
          if (user) {
            this.blockedUser.push(user.blockedTo);
          }
        }
        localStorage.setItem('blockedUsers', JSON.stringify(this.blockedUser));
      })
    //alert(" localStorage.setItem('blockedUsers') in header :"+ localStorage.getItem('blockedUsers'));
          // PSE
          this.ngOnInit();
          // PSE
  }
  getCurrentUser() {
    firebase.database().ref().child('users/' + localStorage.getItem('uid'))
      .once('value', (snapshot) => {
        var user = snapshot.val();
        if (user) {
          localStorage.setItem('currentUser', (user))
        }
      });
  }

  toProfile(uid) {
    this.router.navigate(['/other-profile', uid]);
  }

  getFavourites() {
    var self = this;
    self.myFavourites = [];
    self.favouritesBy = [];
    if (self.currentUser) {
      if (self.currentUser.favoriteBy) {

        for (var i = 0; i < self.currentUser.favoriteBy.length; i++) {
          self.uid = self.currentUser.favoriteBy[i];
          if (!self.blockedUser.includes(self.uid)) {
            firebase.database().ref().child('users/' + self.uid)
              .once('value', (snapshot) => {
                var user = snapshot.val();
                if (user) {
                  var dob = new Date(user.dob);
                  var timeDiff = Math.abs(Date.now() - dob.getTime());
                  user.userAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
                  self.favouritesBy.push(user);
                  localStorage.setItem('favBy', JSON.stringify(self.favouritesBy));
                }
              });
          }
        }
      }
      if (self.currentUser.myFavourites) {
        for (var i = 0; i < self.currentUser.myFavourites.length; i++) {

          self.uid = self.currentUser.myFavourites[i];
          if (!self.blockedUser.includes(self.uid)) {
            firebase.database().ref().child('users/' + self.uid)
              .once('value', (snapshot) => {
                var user = snapshot.val();
                if (user) {
                  var dob = new Date(user.dob);
                  var timeDiff = Math.abs(Date.now() - dob.getTime());
                  user.userAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
                  self.myFavourites.push(user);
                  localStorage.setItem('favMy', JSON.stringify(self.myFavourites));
                }
              });
          }
        }
      }
    }
    console.log(" self.favouritesBy", self.favouritesBy);
    console.log(" self.myFavourites", self.myFavourites);

          // PSE
          this.ngOnInit();
          // PSE
  }

  openModal(user) {
    this.user = user;
    this.modalImg = user.profileUrl;
  }

  toOthersProfile() {
    this.router.navigate(['/other-profile', this.user.uid]);
  }
}
