import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataCollectionsService } from '../data-collections.service';
import * as firebase from 'firebase';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-other-profile',
  templateUrl: './other-profile.component.html',
  styleUrls: ['./other-profile.component.css']
})
export class OtherProfileComponent implements OnInit {
  public language :string;
  public uid: any;
  public loading = false;
  public alreadyFav = false;
  public otherUser: any;
  public currentUser: any;
  public favoriteBy: Array<any> = [];
  public myFavourites: Array<any> = [];
  public myTraits:any;
  public myHobbies :any;
  public goingOuts:any;
  public myMovies:any;
  public musicList :any;
  public myBooks:any;
  public user = {
    lifeStyle:{myTraits:"",myHobbies:"",goingOuts:"",myMovies:"",musicList:"",myBooks:""},
    uid: "",
    
    profileUrl:"",
    sexe:"",
    nickName:"",
    userAge:"",
    homeTown:"",
    description:"",
    status:"",
    handyplus:"",
    height:"",
    eyes:"",
    hair:"",
    silhouette:"",
    searchingFor:{status:"",handyplus:"",city:""}
  };
  public showModal: any;
  public reason: any;
  public reasonTxt: any="";

  constructor(route: ActivatedRoute,
    public service: DataCollectionsService,
    public router: Router,
    private _translate: TranslateService) {

    this.uid = route.snapshot.params.id;
    this.getUserData();
  }

  ngOnInit() {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    }else
    {this.language = this._translate.currentLang;}
  
    this.checkAlreadyFav();
  }
  
  addToBlackList() {
    var updates = {};  
    var postData = {
      blockedBy: localStorage.getItem('uid'),
      blockedTo: this.uid,
      reason: this.reason,
      reasonTxt: this.reasonTxt,
      timestamp: Number(new Date())
    }
    var postKey = firebase.database().ref('blockedUsers/').push().key;
    updates['blockedUsers/' + postKey] = postData;
    firebase.database().ref().update(updates).then(() => {
      this.router.navigate(['/home']);
    })
  }

  getUserData() {
    var self = this;
    self.loading = true;
    firebase.database().ref().child('users/' + self.uid)
      .once('value', function (snapshot) {
        var user = snapshot.val();
        if (user) {
          self.otherUser = user;
          var dob = new Date(user.dob);
          var timeDiff = Math.abs(Date.now() - dob.getTime());
          user.userAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
          self.user = user;
          console.log("other user is :", user);
        }
        self.loading = false;
      });
  }

  checkAlreadyFav() {
    var self = this;
    firebase.database().ref().child('users/' + localStorage.getItem('uid'))
      .once('value', function (snapshot) {
        var user = snapshot.val();
        self.currentUser = user;
        if (user.myFavourites) {
          for (var i = 0; i < user.myFavourites.length; i++) {
            if (user.myFavourites[i] == self.uid) {
              self.alreadyFav = true;
            }
          }
        }
      });
  }

  addtoFavourite() {
    //First Adding to my Favourites
    var self = this;
    firebase.database().ref().child('users/' + localStorage.getItem('uid'))
      .once('value', (snapshot) => {
        var currentUser = snapshot.val();
        if (currentUser.myFavourites) {
          currentUser.myFavourites.push(self.user.uid);
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
        else {
          self.myFavourites = [];
          self.myFavourites.push(self.user.uid);
          currentUser.myFavourites = self.myFavourites;
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
        var updates = {};
        updates['/users/' + currentUser.uid] = currentUser;
        firebase.database().ref().update(updates);
        self.alreadyFav = true;
      });

    //Now Adding to their Favourites
    if (self.otherUser.favoriteBy) {
      self.otherUser.favoriteBy.push(localStorage.getItem('uid'));
    }
    else {
      self.favoriteBy = [];
      self.favoriteBy.push(localStorage.getItem('uid'));
      self.otherUser.favoriteBy = self.favoriteBy;
    }
    var updates = {};
    updates['/users/' + self.otherUser.uid] = self.otherUser;
    firebase.database().ref().update(updates);
  }

  removeFavourite() {
    var self = this;
    for (var i = 0; i < self.currentUser.myFavourites.length; i++) {
      if (self.currentUser.myFavourites[i] == self.otherUser.uid) {
        self.currentUser.myFavourites.splice(i, 1);
        localStorage.setItem('currentUser', JSON.stringify(self.currentUser));
      }
    }
    var updates = {};
    updates['/users/' + localStorage.getItem('uid') + "/" + "myFavourites"] = self.currentUser.myFavourites;
    firebase.database().ref().update(updates);
    self.alreadyFav = false;

    // Now Remove from their favorites...
    for (var i = 0; i < self.otherUser.favoriteBy.length; i++) {
      if (self.otherUser.favoriteBy[i] == localStorage.getItem('uid')) {
        self.otherUser.favoriteBy.splice(i, 1);
      }
    }
    var updates = {};
    updates['/users/' + self.otherUser.uid + "/" + "favoriteBy"] = self.otherUser.favoriteBy;
    firebase.database().ref().update(updates);
  }

  chatScreen(user, smile) {
    if (this.alreadyFav) {
      user.alreadyFav = true;
    }
    if (smile == 'smile') {
      this.service.smile = true;
    }
    

    this.service.user = user;
    this.router.navigate(['/chat']);
  }

  showAlert() {
    this.showModal = true;
  }

}
