import { Component, OnInit, Input,  } from '@angular/core';
import { Router } from '@angular/router';
import { DataCollectionsService } from '../data-collections.service';
import { TranslateService  } from '@ngx-translate/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {  HttpClient,  } from '@angular/common/http';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-pre-home',
  templateUrl: './pre-home.component.html',
  styleUrls: ['./pre-home.component.css'],

})

export class PreHomeComponent implements OnInit {
 public language :string; 
  public nickName: any;
  public errorMsg: any;
  public formGroup: FormGroup;
  public email: any;
  public name: any;
  public message: any;
  submitting = false;
  submitted = false;
  private submissionForm: AngularFirestoreCollection<any>;

  constructor(
    public http: HttpClient,
    public _fb: FormBuilder,
    private firestore: AngularFirestore,
    public router: Router,
    public service: DataCollectionsService,
    public _translate: TranslateService
  ) {
    if (localStorage.getItem('user_logged_in') == 'true') {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else { this.language = this._translate.currentLang; }

    this.submissionForm = this.firestore.collection('submissions');
    this.formGroup = this._fb.group({
      email: ['', Validators.compose([
        Validators.required,
      ])],
      name: ['', Validators.compose([
        Validators.required
      ])],
      message: ['', Validators.compose([
        Validators.required
      ])],
    })
  }

  submitData(value: any) {
    console.log(this.submitted);
    console.log(value);

    this.submitting = true;
    this.submissionForm.add(value).then(res => {
      this.submitted = true;
    }).catch(err => { console.log(err); this.submitting = false; });
    // ).finally(()=>{this.submitting = false;});
    this.submitting = false;
  }

  onChange(value) {
    this.errorMsg = false;
    if (!value) {
      this.errorMsg = true;
    }
  }

  onActivate(event) {
    console.log(window);
    window.scroll(0, 0);
  }

  toSignUp() {
    this.service.nickName = this.nickName;
    this.router.navigate(['/signup']);
  }
 
}
