import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-welcome-msg',
  templateUrl: './welcome-msg.component.html',
  styleUrls: ['./welcome-msg.component.css']
})

export class WelcomeMsgComponent implements OnInit {
 public  language:string;
  public profileImage: any;
  public profileUrl: any;
  public showError = false;
  public loading = false;

  constructor(public router: Router, 
    public _translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else { this.language = this._translate.currentLang; }
  }

  toHome() {
    if (firebase.auth().currentUser.emailVerified !== true) {
      this.showError = true;
      this.router.navigate(['/welcome-msg']);
      window.location.reload();
      this.showError = true;
    } else {

      if (!this.profileUrl) {
        this.showError = false;

        this.loading = false;
        this.router.navigate(['/home']);
        window.location.replace('/home');
      }
      else {
        this.showError = false;

        this.saveImage();
      }
    }


  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return firebase.auth().currentUser.sendEmailVerification()
      .then(() => { this.toHome(); });
  }
 
  onChangeFile(event: EventTarget) {
    var self = this;
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.profileImage = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      self.profileUrl = reader.result;

    }
  }


  saveImage() {
    var self = this;
    self.loading = true;
    let storageRef = firebase.storage().ref();
    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    storageRef.child('profileImages/' + filename).put(self.profileImage, metadata)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.profileUrl = downloadURL;
          self.saveProfileUrl();
        });
      });
  }

  saveProfileUrl() {
    var uid = localStorage.getItem('uid');
    var self = this;
    var updates = {};
    updates['/users/' + uid + "/" + "profileUrl"] = self.profileUrl ? self.profileUrl : "";
    firebase.database().ref().update(updates).then(() => {
      self.loading = false;
      localStorage.setItem('profileUrl', self.profileUrl);
      self.router.navigate(['/home']);
      // window.location.replace('/home');
    });
  }


}
