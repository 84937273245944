import { Injectable } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { DataCollectionsService } from './data-collections.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public afAuth: AngularFireAuth, public router: Router, public service: DataCollectionsService,
            private afs: AngularFirestore
    ) { }

  FacebookAuth() {
    /* this.AuthLogin(new firebase.auth.FacebookAuthProvider()); */
  }

  // Auth logic to run auth providers
  AuthLogin() {
    this.afAuth.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider()).then((result) => {
      this.getUserData(result);
      debugger;
      console.log('You have been successfully logged in!')
    }).catch((error) => {
      console.log(error)
    });

    /*  this.afAuth.auth.signInWithPopup(provider)
       .then((result) => {
         this.getUserData(result);
        console.log(error)
          debugger;
         console.log('You have been successfully logged in!')
       }).catch((error) => {
       }) */
  }

  AuthLoginFacebook() {
    this.afAuth.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider()).then((result) => {
      this.getUserData(result);
      console.log("user facebook connecté :");
      debugger;
      console.log('You have been successfully logged in!')
    }).catch((error) => {
      console.log(error)
    });

    /*  this.afAuth.auth.signInWithPopup(provider)
       .then((result) => {
         this.getUserData(result);
        console.log(error)
          debugger;
         console.log('You have been successfully logged in!')
       }).catch((error) => {
       }) */
  }
  AuthLoginGoogle() {
    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((result) => {
      this.getUserData(result);
      debugger;
      console.log('You have been successfully logged in Google!')
    }).catch((error) => {
      console.log(error)
    });

    /*  this.afAuth.auth.signInWithPopup(provider)
       .then((result) => {
         this.getUserData(result);
         debugger;
         console.log('You have been successfully logged in!')
       }).catch((error) => {
         console.log(error)
       }) */
  }

  getUserData(result) {
    firebase.database().ref().child('users' + '/' + result.user.uid).once('value', (snapshot) => {
      var user = snapshot.val();
      if (user) {
        localStorage.setItem('fullName', user.nickName);
        if (user.profileUrl) {
          localStorage.setItem('profileUrl', user.profileUrl);
        }
        localStorage.setItem('email', user.email);
        localStorage.setItem('user_logged_in', 'true');
        localStorage.setItem('uid', user.uid);
        this.router.navigate(['/home']);
        window.location.replace('/home');

      }
      else {
        var postData: any = {
          nickName: result.user.displayName,
          email: result.user.email,
          uid: result.user.uid,
          timestamp: Number(new Date()),
          interestedIn: this.service.user.interestedIn || "",
          lookingFor: this.service.user.lookingFor || "",
          dob: this.service.user.dob || "",
          homeTown: this.service.user.homeTown || "",
          myUnique: this.service.user.myUnique || "",
          description: this.service.user.description || "",
          searchingQualities: this.service.user.searchingQualities || "",
          tos: this.service.user.tos || "",
          privacyPolicy: this.service.user.privacyPolicy || "",
          status: "single",
          children: 0,
          height: "",
          eyes: "",
          hair: "",
          silhouette: "",
          handyplus: ""
        };
        var updates = {};
        updates['/users/' + result.user.uid] = postData;
        firebase.database().ref().update(updates).then(() => {
          localStorage.setItem('fullName', result.user.displayName);
          localStorage.setItem('email', result.user.email);
          localStorage.setItem('user_logged_in', 'true');
          localStorage.setItem('uid', result.user.uid);
          this.router.navigate(['/welcome-msg']);
          window.location.replace('/welcome-msg');

        });

      }
    })
  }

////
loginFacebookUser() {
  alert("hello in loginFacebookUser service");
  return this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider())
    .then(credential =>{ 
      console.log("facebook register is  :",credential.user );
      this.updateUserData(credential.user)});
}
loginGoogleUser() {
  return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
    .then(credential => this.updateUserData(credential.user))
}
loginEmailUser(email: string, pass: string) {
  return new Promise((resolve, reject) => {
    this.afAuth.auth.signInWithEmailAndPassword(email, pass)
      .then(userData => {//resolve(userData);
        this.getUserData(userData);},
      err => reject(err));
  });
}
isAuth() {
  return this.afAuth.authState.pipe(map(auth => auth));
}

private updateUserData(result) {
  console.log("result update user after register in fb :", result);
   var postData: any = {
    nickName: result.displayName,
    email: result.email,
    uid: result.uid,
 //   timestamp: Number(new Date()),
    profileUrl :result.photoURL,
    
  };
  localStorage.setItem('fullName', result.displayName);
  localStorage.setItem('email', result.email);
  localStorage.setItem('user_logged_in', 'true');
  localStorage.setItem('uid', result.uid);
  localStorage.setItem('profileUrl', result.photoURL);
  localStorage.setItem('popupSeen', 'false');

 /* var updates = {};
  updates['/users/' + result.uid] = postData;
  firebase.database().ref().update(updates).then(() => {
  
    //this.router.navigate(['/welcome-msg']);
    //window.location.replace('/welcome-msg');

  });*/

}

}
