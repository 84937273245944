import { Component, OnInit, NgZone } from '@angular/core';
import { DataCollectionsService } from '../data-collections.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { TranslateService } from '@ngx-translate/core';

////////essai reconnaissance vocale
/*import {
  RxSpeechRecognitionService,

  resultList,
  SpeechRecognitionService,
  SpeechRecognitionGrammars,

} from '@kamiazya/ngx-speech-recognition';*/


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  /* providers: [
     RxSpeechRecognitionService,
  /  SpeechRecognitionService,
 
   ],*/
})
export class ChatComponent implements OnInit {

  public recipient: any = {};
  public uid: any;
  public loading = false;
  public chatKey: string = "";
  public chats: Array<any> = [];
  public allUsers: Array<any> = [];
  public allChats: Array<any> = [];
  public status: any;
  public activeThread: Array<any> = [];
  public activeThreadIndex: any;
  public firstDelete: any;
  public showEmptyChat = false;
  public recentChats: Array<any> = [];
  public image: any;
  public chatImg: any;
  public modalImg: any;
  public container: any;
  public chatRef: firebase.database.Reference;
  public showSmgDetails = false;
  public newMessage: any = {
    senderId: "",
    message: "",
    timestamp: Number(new Date()),
    recipientId: "",
    checked: "false",
  }

  public blockedUser: Array<any> = [];

  inputText: string = '';
  openPopup: Function;
  setPopupAction(fn: any) {
    this.openPopup = fn;
  }

  toProfile(uid) {
    this.router.navigate(['/other-profile', uid]);
  }
  message = '';
  public speech = false;
  language: string;
  constructor(
    //  public serviceSpeech: RxSpeechRecognitionService,
    public service: DataCollectionsService, 
    public _translate: TranslateService,
    private zone: NgZone,
    public router: Router,
  ) {
    if (this.language != localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else {
    this.language = this._translate.currentLang;
      this._translate.use(localStorage.getItem('lg'));
    }
    this.uid = localStorage.getItem('uid');
    //  console.log("browser :" + this.getBrowserName());
    //   if (this.getBrowserName() != "chrome") { this.speech = false; }

  }
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  //essai Vocale
  /* listen() {
 
 
     this.serviceSpeech
       .listen()
       .pipe(resultList)
       .subscribe((list: SpeechRecognitionResultList) => {
         this.message = list.item(0).item(0).transcript;
         console.log('RxComponent:onresult', this.message, list);
         this.newMessage.message = this.newMessage.message + this.message;
       });
   
   }*/
  ///
  ngOnInit() {
    this.blockedUser = JSON.parse(localStorage.getItem('blockedUsers'));
    this.getAllUsers();
    if (this.service.user) {
      this.showSmgDetails = true;
      this.recipient = this.service.user;

      var dob = new Date(this.recipient.dob);
      var timeDiff = Math.abs(Date.now() - dob.getTime());
      this.recipient.userAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      this.newMessage.senderId = this.uid;
      this.newMessage.recipientId = this.recipient.uid;

      this.getActiveChat(this.recipient, 0);

    }

    if (this.service.smile) {
      this.service.smile = false;
      this.newMessage.message = "😄";
      debugger;
      this.sendMessage();
    }
  }

  ngAfterViewInit() {
    this.container = document.getElementById("scrollSecrion");
    if (this.container) {
      this.container.scrollTop = this.container.scrollHeight;
    }
  }

  getActiveChat(recipient, index) {
    // this.showSmgDetails=true;
    this.recipient = recipient;

    this.chats = [];
    this.status = null;
    if (this.uid < recipient.uid) {
      this.chatKey = this.uid + "-" + recipient.uid;
    } else {
      this.chatKey = recipient.uid + "-" + this.uid;
    }
    // check fav or not
    if (recipient.favoriteBy) {
      for (var i = 0; i < recipient.favoriteBy.length; i++) {
        if (recipient.favoriteBy[i] == this.uid) {
          recipient.alreadyFav = true;
        }
      }
    }
    firebase.database().ref().child(`chats/${this.chatKey}/status`)
      .once('value', (snapshot) => {
        this.status = snapshot.val();
        this.chatRef = firebase.database().ref().child(`chats/${this.chatKey}/messages`);
        this.chatRef.on("child_added", (snapshot) => {
          this.zone.run(() => {
            if (this.status) {
              if (this.status.length == 1) {
                if (this.status[0].deletedBy == localStorage.getItem('uid')) {
                  if (this.checkMsgTimestamp(snapshot.val(), this.status)) {
                    this.chats.push(snapshot.val());
                  }
                }
                else {
                  this.chats.push(snapshot.val());
                }
              }
              else {
                if (this.checkMsgTimestamp(snapshot.val(), this.status)) {
                  this.chats.push(snapshot.val());
                }
              }
            }
            else {
              this.chats.push(snapshot.val());
            }

          });
        });

      });
    this.activeThread = [];
    this.activeThread[index] = [index];
    this.activeThreadIndex = index;   

          // PSE
          this.ngOnInit();
          // PSE
  }

  SmgLu(recipient) {

    var updates = {};

    for (var key in this.allChats) {
      var chat = this.allChats[key];
      if (chat.recipientId == recipient.uid) {
        for (var key1 in chat.messages) {
          var message = chat.messages[key1];
          if (message.checked == "false" && message.senderId == recipient.uid) {

            updates[`/chats/${chat.key}/messages/${key1}/checked`] = "true";
            console.log(updates);
            firebase.database().ref().update(updates).then(() => {
              this.loading = false;
              message.checked == "true";
            });          
          }
        }
      }
    }

  }

  refresh() {
    this.allChats = [];
    this.recentChats = [];
    this.getMyChats();
  }

  getActiveChat2(recipient, index) {
    this.SmgLu(recipient);
    this.refresh();
    this.service.user = recipient;
    this.showSmgDetails = true;
    this.countSmg(recipient);
    this.getActiveChat(recipient, 0);
  }

  getActiveChat22(recipient, index) {
    if (index != 0) {
      this.SmgLu(recipient);
      this.refresh();
      this.countSmg(recipient.uid);
    }
    this.showSmgDetails = true;
    this.recipient = {};
    this.recipient = recipient;

    this.chats = [];
    this.status = null;
    if (this.uid < recipient.uid) {
      this.chatKey = this.uid + "-" + recipient.uid;
    } else {
      this.chatKey = recipient.uid + "-" + this.uid;
    }
    // check fav or not
    if (recipient.favoriteBy) {
      for (var i = 0; i < recipient.favoriteBy.length; i++) {
        if (recipient.favoriteBy[i] == this.uid) {
          recipient.alreadyFav = true;
        }
      }
    }

    firebase.database().ref().child(`chats/${this.chatKey}/status`)
      .once('value', (snapshot) => {
        this.status = snapshot.val();
        this.chatRef = firebase.database().ref().child(`chats/${this.chatKey}/messages`);
        this.chatRef.on("child_added", (snapshot) => {
          this.zone.run(() => {
            if (this.status) {
              if (this.status.length == 1) {
                if (this.status[0].deletedBy == localStorage.getItem('uid')) {
                  if (this.checkMsgTimestamp(snapshot.val(), this.status)) {
                    this.chats.push(snapshot.val());
                  }
                }
                else {
                  this.chats.push(snapshot.val());
                }
              }
              else {
                if (this.checkMsgTimestamp(snapshot.val(), this.status)) {
                  this.chats.push(snapshot.val());
                }
              }
            }
            else {
              this.chats.push(snapshot.val());
            }
          });
        });

      });

    this.activeThread = [];
    this.activeThread[0] = [0];
    this.activeThreadIndex = 0;

  }

  checkMsgTimestamp(chat, status) {
    if (status[0].deletedBy == localStorage.getItem('uid')) {
      if (chat.timestamp > status[0].timestamp) {
        return true;
      }
    }
    else if (chat.timestamp > status[1].timestamp) {
      return true;
    }
  }

  getAllUsers() {
    var self = this;
    self.loading = true;
    return firebase.database().ref().child('users')
      .once('value', (snapshot) => {
        var users = snapshot.val();
        for (var key in users) {
          var user = users[key];
          user.key = key;
          if (!self.blockedUser.includes(user.uid)) {
            self.allUsers.push(user);
          }
        }
        self.loading = false;
        this.getMyChats();
      });
  }

  CountMsgNotRead() {
    let badgeCount = 0;
    for (var key in this.allChats) {
      var chat = this.allChats[key];
      for (var key1 in chat.messages) {
        var message = chat.messages[key1];
        if (message.checked == "false" && message.senderId != localStorage.getItem('uid')) {
          badgeCount++;
        }
      }
    }
    return badgeCount;
  }

  countSmg(recipient) {
    let cmp = 0;
    for (var key in this.allChats) {
      var chat = this.allChats[key];
      if (chat.recipientId == recipient) {
        for (var key1 in chat.messages) {
          var message = chat.messages[key1];
          if (message.checked == "false" && message.senderId == recipient) {
            cmp++;
          }
        }
      }
    }
  
    return cmp;

  }

  getMyChats() {
    var self = this;

    return firebase.database().ref().child('chats')
      .once('value', (snapshot) => {
        var chats = snapshot.val();
        for (var key in chats) {
          var chat = chats[key];
          chat.key = key;
          var chatKey = chat.key;
          var parts = chatKey.split("-");
          var part1 = parts[0];
          var part2 = parts[1];
          if (part1 == localStorage.getItem('uid') || part2 == localStorage.getItem('uid')) {
            if (localStorage.getItem('uid') == part1) {
              chat.recipientId = part2;
            }
            if (localStorage.getItem('uid') == part2) {
              chat.recipientId = part1;
            }
            if (!chat.status) {
              self.allChats.push(chat);
            }
            else {
              if (chat.status.length == 1) {
                var checkArray = [];
                if (chat.status[0].deletedBy == localStorage.getItem('uid')) {
                  for (var key in chat.messages) {
                    var message = chat.messages[key];
                    if (this.checkMsgTimestamp(message, chat.status)) {
                      checkArray.push(true);
                    }
                    else {
                      checkArray.push(false);
                    }
                  }
                  if (checkArray.includes(true)) {
                    self.allChats.push(chat);
                  }
                }
                else {
                  self.allChats.push(chat);
                }
              }
              else {
                var checkArray = [];
                if (chat.status[0].deletedBy == localStorage.getItem('uid')) {
                  for (var key in chat.messages) {
                    var message = chat.messages[key];
                    if (this.checkMsgTimestamp(message, chat.status)) {
                      checkArray.push(true);
                    }
                    else {
                      checkArray.push(false);
                    }
                  }
                  if (checkArray.includes(true)) {
                    self.allChats.push(chat);
                  }
                }
                else {
                  for (var key in chat.messages) {
                    var message = chat.messages[key];
                    if (this.checkMsgTimestamp(message, chat.status)) {
                      checkArray.push(true);
                    }
                    else {
                      checkArray.push(false);
                    }
                  }
                  if (checkArray.includes(true)) {
                    self.allChats.push(chat);
                  }
                }
              }
            }
          }
        }
        if (!this.service.user && this.allChats.length == 0) {
          this.showEmptyChat = true;
        }       

        this.fetchChatUsers();
        this.CountMsgNotRead();
        if (this.CountMsgNotRead() > 0) {

          if (document.getElementById('badgeCountTotal')) {
            document.getElementById('badgeCountTotal').innerText = this.CountMsgNotRead().toString();
          }
        } else {

          document.getElementById('badgeCountTotal').setAttribute("hidden", 'true');

        }
      });


  }

  fetchChatUsers() {
    this.recentChats = [];
    if (!this.service.user) {
      for (var i = 0; i < this.allUsers.length; i++) {
        if (this.allUsers[i].uid == this.allChats[0].recipientId) {
          this.recipient = this.allUsers[i];
        }
      }
      this.getActiveChat(this.recipient, 0);
    }
    else if (this.allChats.length == 0) {
      this.recentChats[0] = this.recipient;
    }
    if (!this.recipient.uid) {
      var recipientUid = this.allChats[0].recipientId;
      for (var i = 0; i < this.allUsers.length; i++) {
        if (recipientUid == this.allUsers[i].uid) {
          this.getActiveChat(this.allUsers[i], 0);
        }
      }
    }
    this.newMessage.senderId = this.uid;
    this.newMessage.recipientId = this.allUsers[0].uid;
    for (var i = 0; i < this.allUsers.length; i++) {
      for (var j = 0; j < this.allChats.length; j++) {
        if (this.service.user) {
          this.recentChats[0] = this.recipient;
          if (this.allUsers[i].uid == this.allChats[j].recipientId &&
            this.recentChats[0].uid != this.allChats[j].recipientId) {
            this.recentChats.push(this.allUsers[i]);
          }
        }
        else {
          if (this.allUsers[i].uid == this.allChats[j].recipientId) {
            this.recentChats.push(this.allUsers[i]);
          }
        }
      }
    }  
  
  }

  deleteChat() {
    firebase.database().ref().child('chats/' + this.chatKey + '/status')
      .once('value', (snapshot) => {
        var status = snapshot.val();
        if (status == null) {
          status = [];
          var object = {
            deletedBy: this.uid,
            timestamp: Number(new Date())
          }
          status.push(object);
        }
        else {
          if (status.length == 1) {
            if (status[0].deletedBy == localStorage.getItem('uid')) {
              status[0].deletedBy = localStorage.getItem('uid');
              status[0].timestamp = Number(new Date());
            }
            else {
              var object = {
                deletedBy: this.uid,
                timestamp: Number(new Date())
              }
              status.push(object);
            }
          }
          else {
            if (status[0].deletedBy == localStorage.getItem('uid')) {
              status[0].deletedBy = localStorage.getItem('uid');
              status[0].timestamp = Number(new Date());
            }
            else {
              status[1].deletedBy = localStorage.getItem('uid');
              status[1].timestamp = Number(new Date());
            }
          }
        }
        var updates = {};
        updates[`/chats/${this.chatKey}/status`] = status;
        firebase.database().ref().update(updates).then(() => {
          this.recentChats.splice(this.activeThreadIndex, 1);
          if (this.recentChats.length == 0) {
            this.showEmptyChat = true;
          }
          else {
            this.getActiveChat(this.recentChats[0], 0);
          }
        });
      });
  }

  sendMessage() {
    debugger;
    if (!this.newMessage.message.trim() && !this.chatImg) {
      return;
    }
    if (this.chatImg) {
      this.saveImage();
    }
    else {
      this.uploadMsg();
    }
  }

  uploadMsg() {
    this.newMessage.timestamp = Number(new Date());
    var key = firebase.database().ref().child(`chats/${this.chatKey}/messages`).push(this.newMessage).key;
    this.newMessage.message = "";
    this.newMessage.cheched = "false";
    this.chatImg = "";
    var updatedChats = this.recentChats;
    this.recentChats = [];
    this.recentChats[0] = this.recipient;
    for (var i = 0; i < updatedChats.length; i++) {
      if (this.recentChats[0].uid != updatedChats[i].uid) {
        this.recentChats.push(updatedChats[i]);
      }
    }
    this.activeThread = [];
    this.activeThread[0] = [0];
    this.activeThreadIndex = 0;
  }

  onChangeFile(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.image = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.chatImg = reader.result;
    }
  }

  saveImage() {
    var self = this;
    self.loading = true;
    let storageRef = firebase.storage().ref();
    var metadata = {
      contentType: 'image/jpeg/png'
    };
    const filename = Math.floor(Date.now() / 1000);
    storageRef.child('chatImages/' + filename).put(self.image, metadata)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          self.newMessage.image = downloadURL;
          self.loading = false;
          self.uploadMsg();
        });
      });
  }

  toHome() {
    this.router.navigate(['/home']);
  }

  openImage(img) {
    this.modalImg = img;
  }

}
