import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as firebase from 'firebase';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DataCollectionsService } from '../data-collections.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public language: string;
  public onLoginForm: FormGroup;
  public email: any;
  public password: any;
  public uid: any;
  public loading: any = false;
  public errorMessage: any;
  public errorAlert: any = false;
  public user: any = {};

  constructor(public router: Router,
    public _fb: FormBuilder,
    public authService: AuthService,
    private _translate: TranslateService,
    public service: DataCollectionsService) {

    if (localStorage.getItem('lg')) {
      this.language = localStorage.getItem('lg');
    } else { this.language = this._translate.currentLang; }
  }

  ngOnInit() {
    this.onLoginForm = this._fb.group({
      email: ['', Validators.compose([
        Validators.required,
      ])],
      password: ['', Validators.compose([
        Validators.required
      ])],
    })
  }

  userLogin() {
    var self = this;
    self.loading = true;
    firebase.auth().signInWithEmailAndPassword(self.email, self.password).then((user) => {
      if (user) {
        this.uid = user.user.uid;
        self.getUserData();
      }
    })
      .catch((error) => {
        self.loading = false
        var errorMessage = error.message;
        this.errorMessage = errorMessage;
        this.errorAlert = true;
      })
  }

  getUserData() {
    var self = this;
    firebase.database().ref().child('users' + '/' + this.uid).once('value', (snapshot) => {
      var user = snapshot.val();
      if (user) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('fullName', user.nickName);
        localStorage.setItem('profileUrl', user.profileUrl);
        localStorage.setItem('email', user.email);
        localStorage.setItem('user_logged_in', 'true');
        localStorage.setItem('uid', user.uid);
        // self.router.navigate(['/home']);
        // window.location.replace('/home');
        /*
        self.router.navigate(['/home']);
        window.location.replace('/home');
        */
        self.router.navigate(['/home']);
      }
      else {
        self.router.navigate(['/login']);
        /*
        window.location.replace('/login');
        self.router.navigate(['/login']);
        */
        // window.location.replace('/login');
      }
      this.loading = false;
    })
  }
}


